<template>
  <div class="loufengBigPicture">
    <!-- 返回按钮 -->
    <div class="topBtn back" @click="$router.go(-1)">
      <van-icon name="arrow-left" size="25" color="white" />
    </div>
    <van-swipe class="my-swipe" @change="onChange">
      <van-swipe-item v-for="(item, index) in loufengSwipe.images" :key="index">
        <ImgDecypt class="tag-img" :src="item" fill="cover"></ImgDecypt>
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/{{ loufengSwipe.images ? loufengSwipe.images.length : 0 }}</div>
      </template>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: 'loufengBigPicture',
  data() {
    return {
      loufengSwipe: {},
      current: 0
    }
  },
  created() {
    this.getLoufengSwipe();
  },
  methods: {
    getLoufengSwipe() {
      let loufengSwipe = this.$route.params.loufengSwipe;
      if(!loufengSwipe) {
        this.loufengSwipe = JSON.parse(localStorage.getItem('loufengSwipe'));
      } else {
        this.loufengSwipe = loufengSwipe;
        localStorage.setItem('loufengSwipe', JSON.stringify(this.$route.params.loufengSwipe));
      }
    },
    onChange(index) {
      this.current = index;
    }
  }
}
</script>

<style scoped lang="scss">
  .loufengBigPicture {
    background-color: black;
    .my-swipe{
      height: 80vh;
      color: #fff;
      font-size: 20px;
      text-align: center;
      margin-top: 60px;
      .custom-indicator {
        position: absolute;
        right: 16px;
        bottom: 10px;
        width: 44px;
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 15px;
      }
    }
  }
  .tag-img{
    width:100%;
    height:100%;
  }
  // 顶部按钮
  .topBtn {
    height: 40px;
    width: 40px;
    background-color: rgba(255,255,255,0.2);
    border-radius: 50%;
    position: fixed;
    z-index: 15;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // 返回
  .back {
    left: 10px;
  }
</style>
